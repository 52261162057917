<template>
	<ImagesBoard :images="images" :loading="loading" @update="loadData" />
</template>

<script>
import { ImagesBoard } from '@/modules/partner/components'
import { mapGetters } from 'vuex'
import { merchantService } from '../../services'

export default {
	name: '',
	components: {
		ImagesBoard,
	},
	data() {
		return {
			loading: false,
			images: [],
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
	},
	beforeMount() {
		this.loadData()
	},
	methods: {
		async loadData() {
			try {
				this.loading = true
				this.images = await merchantService.getImages(
					this.selectedMerchantId
				)
			} catch (error) {
				//
			} finally {
				this.loading = false
			}
		},
	},
}
</script>
